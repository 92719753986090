import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 34,
  },
  path: {
    fill: '#7df3e1',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 115 39"
      fill="none"
    >
      <path
        d="M4.3176 4.96602C4.00329 5.0946 3.66659 5.15951 3.32701 5.15699C1.87901 5.15699 0.742007 4.04399 0.742007 2.57899C0.740277 2.23933 0.805996 1.90269 0.935367 1.58862C1.06474 1.27455 1.25519 0.989287 1.49565 0.749381C1.73612 0.509475 2.02181 0.319698 2.33618 0.191058C2.65056 0.0624183 2.98735 -0.00252522 3.32701 -5.69731e-06C3.66668 -0.00252522 4.00346 0.0624183 4.31783 0.191058C4.6322 0.319698 4.91791 0.509475 5.15837 0.749381C5.39884 0.989287 5.58928 1.27455 5.71865 1.58862C5.84802 1.90269 5.91374 2.23933 5.91201 2.57899C5.9136 2.91858 5.84779 3.2551 5.71836 3.56906C5.58893 3.88302 5.39846 4.16816 5.15801 4.40796C4.91756 4.64776 4.63191 4.83745 4.3176 4.96602Z"
        fill="#B3F2E3"
      />
      <path
        d="M26.03 28.916H21.8L21.801 28.915V15.9C21.801 13.368 20.072 11.58 17.609 11.58C15.145 11.58 13.379 13.36 13.379 15.9V28.203H13.387V32.83C13.387 36.23 10.658 38.993 7.30001 38.993C3.94201 38.993 1.21201 36.23 1.21201 32.83V8.44999H5.442V32.83C5.44016 33.0745 5.48655 33.3169 5.57852 33.5434C5.6705 33.7699 5.80625 33.9761 5.978 34.15C6.14975 34.324 6.35412 34.4624 6.57942 34.5573C6.80473 34.6522 7.04654 34.7017 7.291 34.703C7.53555 34.7018 7.77748 34.6524 8.0029 34.5576C8.22832 34.4627 8.43281 34.3244 8.60466 34.1504C8.77652 33.9764 8.91236 33.7702 9.0044 33.5436C9.09643 33.3171 9.14285 33.0745 9.14101 32.83V15.824C9.14101 11.066 12.62 7.81199 17.562 7.81199C22.505 7.81199 26.03 11.105 26.03 15.986V28.916Z"
        fill="#B3F2E3"
      />
      <path
        d="M21.33 34.794C21.33 33.329 22.467 32.216 23.915 32.216C24.2546 32.2135 24.5913 32.2784 24.9056 32.407C25.2199 32.5355 25.5056 32.7252 25.746 32.965C25.9865 33.2048 26.1769 33.49 26.3064 33.8039C26.4358 34.1179 26.5016 34.4544 26.5 34.794C26.5 36.26 25.363 37.373 23.915 37.373C22.467 37.373 21.33 36.26 21.33 34.794Z"
        fill="#B3F2E3"
      />
      <path
        d="M37.072 14.624C36.184 14.624 35.488 13.952 35.488 13.064C35.488 12.176 36.184 11.504 37.072 11.504C37.936 11.504 38.656 12.176 38.656 13.064C38.656 13.952 37.936 14.624 37.072 14.624ZM38.368 29H35.776V16.616H38.368V29ZM41.9166 16.616H52.2366V29H49.6446V18.872H44.5086V29H41.9166V16.616ZM56.2716 29V18.872H54.9516V16.616H56.2716V13.4H58.8636V16.616H62.2236V18.872H58.8636V26.72H61.8636V29H56.2716ZM64.5338 16.616H70.2698V18.92H67.1258V29H64.5338V16.616ZM77.9093 29.384C74.0933 29.384 71.3333 26.576 71.3333 22.808C71.3333 19.016 74.1173 16.232 77.9093 16.232C81.7013 16.232 84.5093 19.016 84.5093 22.808C84.5093 26.576 81.7013 29.384 77.9093 29.384ZM77.9093 27.056C80.2373 27.056 81.8693 25.256 81.8693 22.784C81.8693 20.288 80.2373 18.512 77.9093 18.512C75.6053 18.512 73.9493 20.288 73.9493 22.784C73.9493 25.256 75.6053 27.056 77.9093 27.056ZM100.35 22.808C100.35 26.648 97.7104 29.384 94.0624 29.384C92.0944 29.384 90.3904 28.544 89.7664 27.296H89.6944C89.7424 27.536 89.7664 27.848 89.7664 28.496V34.52H87.1744V16.616H89.5744V17.336C89.5744 17.888 89.5504 18.2 89.5264 18.536H89.5984C90.3904 17.216 92.0464 16.232 94.1824 16.232C97.7584 16.232 100.35 18.968 100.35 22.808ZM93.7024 18.512C91.3504 18.512 89.6704 20.288 89.6704 22.784C89.6704 25.28 91.3504 27.056 93.7024 27.056C96.0064 27.056 97.7344 25.28 97.7344 22.784C97.7344 20.288 96.0064 18.512 93.7024 18.512ZM104.17 34.52V32.24H110.626V28.88C110.626 28.4 110.65 28.016 110.674 27.704H110.578C110.338 28.28 109.426 29.168 107.434 29.168C104.77 29.168 102.874 27.08 102.874 24.248V16.616H105.466V24.224C105.466 25.784 106.546 26.84 108.058 26.84C109.546 26.84 110.626 25.784 110.626 24.224V16.616H113.218V34.52H104.17Z"
        fill="#B3F2E3"
      />
    </svg>
  );
};

export default LogoFull;
