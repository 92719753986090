import {
    TechRadarApi,
    TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar';

export class TechRadarClient implements TechRadarApi {
    // @ts-ignore
    async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
        // For example, this converts the timeline dates into date objects
        return {
            quadrants: [
                { id: 'techniques', name: 'Techniques' },
                { id: 'processpatterns', name: 'Process & Patterns' },
                { id: 'platformstools', name: 'Platforms & Tools' },
                { id: 'languagesframeworks', name: 'Languages & Frameworks' },
            ],
            rings: [
                { id: "use", name: "Use", color: "#3884ff" },
                { id: "trial", name: "Trial", color: "#f9c80e" },
                { id: "assess", name: "Assess", color: "#f0932b" },
                { id: "hold", name: "Hold", color: "#eb4d4b" },
            ],
            // @ts-ignore
            entries: [
                {
                    timeline: [
                        {
                            moved: 0,
                            ringId: 'use',
                            date: new Date('2024-05-20'),
                            description:
                                'Azure Service Bus is one of our main integration components, facilitating seamless and reliable communication between diverse applications and services within our infrastructure.',
                        },
                    ],
                    url: 'https://learn.microsoft.com/en-us/azure/service-bus-messaging/service-bus-messaging-overview',
                    key: 'azure-service-bus',
                    id: 'azure-service-bus',
                    title: 'Service Bus',
                    quadrant: 'techniques',
                    description:
                        'Azure Service Bus is a fully managed enterprise message broker with message queues and publish-subscribe topics, enabling reliable cloud messaging between applications and services.',
                },
                {
                    url: "https://azure.microsoft.com/en-us/products/container-apps",
                    key: "azure-container-apps",
                    id: "azure-container-apps",
                    title: "Container Apps",
                    quadrant: "techniques",
                    description: "Azure Container Apps is a managed serverless container service that enables the deployment and scaling of containerized applications in a fully managed environment.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "assess",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://backstage.io/docs/features/software-catalog/system-model/",
                    key: "spotify-system-model",
                    id: "spotify-system-model",
                    title: "Spotify System Model",
                    quadrant: "techniques",
                    description: "The Spotify system model in the Backstage Software Catalog encompasses three core entities: components, APIs, and resources. Components are individual pieces of software such as mobile features, backend services, or data pipelines. APIs are the boundaries between components, enabling interaction and scaling within the software ecosystem. Resources include the necessary infrastructure for components to operate, like databases or storage services. Systems group these entities, allowing teams to manage them cohesively and evolve implementations without disrupting consumers. Domains further categorize systems based on business purposes, creating a structured and comprehensible catalog of software assets ",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "trial",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://learn.microsoft.com/en-us/azure/azure-monitor/app/app-insights-overview",
                    key: "azure-application-insights",
                    id: "azure-application-insights",
                    title: "Application Insights",
                    quadrant: "techniques",
                    description: "Azure Application Insights is a cloud-based service that provides monitoring and diagnostics for web applications, helping to detect and diagnose performance issues and anomalies in real-time.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://www.jetbrains.com/teamcity/",
                    key: "jetbrains-teamcity",
                    id: "jetbrains-teamcity",
                    title: "TeamCity",
                    quadrant: "techniques",
                    description: "Use TeamCity to speed up the delivery of your software in the most cost-optimal way, with any tech stack, at any scale.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "assess",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://git-scm.com/",
                    key: "git",
                    id: "git",
                    title: "Git",
                    quadrant: "techniques",
                    description: "Git is a free and open source distributed version control system designed to handle everything from small to very large projects with speed and efficiency.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://learn.microsoft.com/en-us/azure/cosmos-db/introduction",
                    key: "azure-cosmos-db",
                    id: "azure-cosmos-db",
                    title: "Cosmos DB",
                    quadrant: "techniques",
                    description: "Azure Cosmos DB is a fully managed NoSQL database service designed to provide high availability, low latency, and global distribution for scalable applications.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://learn.microsoft.com/en-us/azure/azure-monitor/overview",
                    key: "azure-monitor",
                    id: "azure-monitor",
                    title: "Azure Monitor",
                    quadrant: "techniques",
                    description: "Azure Monitor is a comprehensive monitoring solution for collecting, analyzing, and responding to monitoring data from your cloud and on-premises environments. You can use Azure Monitor to maximize the availability and performance of your applications and services. It helps you understand how your applications are performing and allows you to manually and programmatically respond to system events.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://www.pagerduty.com/platform/automation/runbook/",
                    key: "runbook-automation",
                    id: "runbook-automation",
                    title: "Runbook Automation",
                    quadrant: "techniques",
                    description: "https://www.pagerduty.com/platform/automation/runbook/",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "assess",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://learn.microsoft.com/en-us/azure/logic-apps/single-tenant-overview-compare",
                    key: "logic-apps",
                    id: "logic-apps",
                    title: "Logic Apps",
                    quadrant: "techniques",
                    description: "https://learn.microsoft.com/en-us/azure/logic-apps/single-tenant-overview-compare",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "hold",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://www.jetbrains.com/help/space/automation.html",
                    key: "jetbrains-space-automation",
                    id: "jetbrains-space-automation",
                    title: "Space Automation",
                    quadrant: "techniques",
                    description: "https://www.jetbrains.com/help/space/automation.html",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "hold",
                            date: new Date("2024-06-13"),
                            description: ""
                        },
                        {
                            moved: 1,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                        
                    ]
                },
                {
                    url: "https://azure.microsoft.com/en-us/products/azure-sql/database",
                    key: "azure-sql",
                    id: "azure-sql",
                    title: "Azure SQL",
                    quadrant: "techniques",
                    description: "https://azure.microsoft.com/en-us/products/azure-sql/database",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://azure.microsoft.com/en-us/products/data-factory",
                    key: "azure-data-factory",
                    id: "azure-data-factory",
                    title: "Data Factory",
                    quadrant: "techniques",
                    description: "https://azure.microsoft.com/en-us/products/data-factory",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://azure.microsoft.com/en-us/products/data-factory",
                    key: "devops",
                    id: "azure-pipelines",
                    title: "Azure Pipelines",
                    quadrant: "techniques",
                    description: "https://azure.microsoft.com/en-us/products/devops/pipelines",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://learn.microsoft.com/en-us/entra/identity/managed-identities-azure-resources/overview",
                    key: "azure-managed-identity",
                    id: "azure-managed-identity",
                    title: "Managed Identity",
                    quadrant: "techniques",
                    description: "https://learn.microsoft.com/en-us/entra/identity/managed-identities-azure-resources/overview",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://learn.microsoft.com/en-us/azure/azure-functions/functions-overview?pivots=programming-language-csharp",
                    key: "azure-functions",
                    id: "azure-functions",
                    title: "Azure Functions",
                    quadrant: "techniques",
                    description: "Azure Functions is a serverless solution that allows you to write less code, maintain less infrastructure, and save on costs. Instead of worrying about deploying and maintaining servers, the cloud infrastructure provides all the up-to-date resources needed to keep your applications running.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://learn.microsoft.com/sv-se/azure/virtual-network/virtual-networks-overview",
                    key: "azure-vnet",
                    id: "azure-vnet",
                    title: "Azure VNET",
                    quadrant: "techniques",
                    description: "Azure Virtual Network is a service that provides the fundamental building block for your private network in Azure. An instance of the service (a virtual network) enables many types of Azure resources to securely communicate with each other, the internet, and on-premises networks.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "trial",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://learn.microsoft.com/sv-se/biztalk/",
                    key: "microsoft-biztalk",
                    id: "microsoft-biztalk",
                    title: "BizTalk",
                    quadrant: "platformstools",
                    description: "Microsoft BizTalk is an enterprise integration platform that automates business processes through the use of adapters to connect various systems and applications within an organization.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "hold",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://www.gather.town",
                    key: "gather",
                    id: "gather",
                    title: "Gather",
                    quadrant: "platformstools",
                    description: "Gather is a virtual space for remote teams to meet, collaborate, and socialize, providing a customizable and interactive environment for hosting events, meetings, and conferences.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-06-13"),
                            description: ""
                        },
                        {
                            moved: 1,
                            ringId: "trial",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://www.pagerduty.com/",
                    key: "pagerduty",
                    id: "pagerduty",
                    title: "PagerDuty",
                    quadrant: "platformstools",
                    description: "PagerDuty is an incident management and response platform that helps organizations detect, escalate, and resolve issues in real-time, ensuring optimal performance and minimal downtime for critical systems and applications.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://azure.microsoft.com/en-us/products/api-management",
                    key: "azure-api-management",
                    id: "azure-api-management",
                    title: "API Management",
                    quadrant: "platformstools",
                    description: "Azure API Management is a fully managed service that enables customers to publish, secure, transform, maintain, and monitor APIs. Use it to secure your APIs and provide access to them to selected consumers.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://azure.microsoft.com/en-us/products/api-management",
                    key: "1Password",
                    id: "1Password",
                    title: "1Password",
                    quadrant: "platformstools",
                    description: "1Password is a password manager that provides a secure and convenient way to store and manage passwords, credit card information, and other sensitive data.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "http://www.jetbrains.com",
                    key: "Youtrack",
                    id: "Youtrack",
                    title: "Youtrack",
                    quadrant: "platformstools",
                    description: "Youtrack is a project management tool that provides a flexible and efficient way to manage tasks, issues, and projects, enabling teams to collaborate effectively and deliver high-quality software products.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "trial",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://www.miro.com",
                    key: "Miro",
                    id: "Miro",
                    title: "Miro",
                    quadrant: "platformstools",
                    description: "Miro is a visual collaboration platform that enables teams to work together on a shared digital whiteboard, fostering creativity, innovation, and productivity.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://www.jetbrains.com",
                    key: "Qodana",
                    id: "Qodana",
                    title: "Qodana",
                    quadrant: "platformstools",
                    description: "Qodana is a static code analysis tool that helps development teams follow agreed quality standards, and deliver readable, maintainable, and secure code. Powered by JetBrains.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://www.jetbrains.com",
                    key: "Backstage",
                    id: "Backstage",
                    title: "Backstage",
                    quadrant: "platformstools",
                    description: "Backstage is an open-source platform for building developer portals, enabling teams to create, share, and collaborate on software projects, services, and infrastructure.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://azure.microsoft.com",
                    key: "visual-studio",
                    id: "visual-studio",
                    title: "Visual Studio",
                    quadrant: "platformstools",
                    description: "Visual Studio is an integrated development environment (IDE) used to develop computer programs, websites, web apps, web services, and mobile apps.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "hold",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://azure.microsoft.com",
                    key: "jetbrains-rider",
                    id: "jetbrains-rider",
                    title: "Rider",
                    quadrant: "platformstools",
                    description: "Jetbrains Rider is a cross-platform .NET IDE based on the IntelliJ platform and ReSharper.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://www.revision.app",
                    key: "revision",
                    id: "Revision",
                    title: "Revision",
                    quadrant: "platformstools",
                    description: "Explain your architectures to anyone in minutes",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://www.revision.app",
                    key: "jetbrains-space",
                    id: "jetbrains-space",
                    title: "Space",
                    quadrant: "platformstools",
                    description: "Jetbrains Space is a unified platform for the entire software development lifecycle, enabling teams to collaborate, plan, code, build, test, deploy, operate, and monitor software projects.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "hold",
                            date: new Date("2024-06-13"),
                            description: ""
                        },
                        {
                            moved: 1,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://www.revision.app",
                    key: "github",
                    id: "github",
                    title: "GitHub",
                    quadrant: "platformstools",
                    description: "GitHub is a web-based platform for version control and collaboration, enabling developers to work together on projects, track changes, and manage code repositories.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "assess",
                            date: new Date("2024-06-13"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://www.revision.app",
                    key: "gitlab",
                    id: "gitlab",
                    title: "GitLab",
                    quadrant: "platformstools",
                    description: "GitLab is a web-based DevOps lifecycle tool that provides a Git repository manager, issue tracking, CI/CD pipeline, and more, enabling teams to collaborate on software projects and automate the software delivery process.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "assess",
                            date: new Date("2024-06-13"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://www.revision.app",
                    key: "microsoft-teams",
                    id: "microsof-teams",
                    title: "Teams",
                    quadrant: "platformstools",
                    description: "Microsoft Teams is a collaboration platform that combines workplace chat, video meetings, file storage, and application integration.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "https://www.revision.app",
                    key: "slack",
                    id: "slack",
                    title: "Slack",
                    quadrant: "platformstools",
                    description: "Slack is a collaboration hub that connects your work with the people you work with, enabling teams to communicate and collaborate effectively in real-time.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "assess",
                            date: new Date("2024-06-13"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "#",
                    key: "strategy",
                    id: "strategy",
                    title: "Strategy",
                    quadrant: "processpatterns",
                    description: "Strategy is the process of deciding on the best course of action to achieve specific goals and objectives, considering the organization's capabilities, resources, and environment.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "assess",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "#",
                    key: "incident-management",
                    id: "tactical-architecture",
                    title: "Tactical Architecture",
                    quadrant: "processpatterns",
                    description: "Tactical architecture is the design of software systems and applications to meet specific business requirements, ensuring that the architecture aligns with the organization's strategic goals and objectives.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "#",
                    key: "pub-sub",
                    id: "pub-sub",
                    title: "Pub/Sub",
                    quadrant: "processpatterns",
                    description: "Pub/Sub is a messaging pattern where senders of messages (publishers) do not program the messages to be sent directly to specific receivers (subscribers). Instead, the publisher categorizes published messages into classes without knowledge of which subscribers, if any, there may be.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "#",
                    key: "adr",
                    id: "adr",
                    title: "ADR",
                    quadrant: "processpatterns",
                    description: "ADR stands for Architecture Decision Record, a document that captures an important architectural decision made along with its context and consequences.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "assess",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "#",
                    key: "merge-request",
                    id: "merge-request",
                    title: "Merge Request",
                    quadrant: "processpatterns",
                    description: "Merge Request enables developers to collaborate on code changes, review code, and merge changes into the main branch.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "#",
                    key: "api-first",
                    id: "api-first",
                    title: "API First",
                    quadrant: "processpatterns",
                    description: "API First is an approach to software development that prioritizes the design and development of APIs before the implementation of other software components, ensuring that the API is well-defined, robust, and scalable.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "trial",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "#",
                    key: "ci-cd",
                    id: "ci-cd",
                    title: "CI/CD",
                    quadrant: "processpatterns",
                    description: "CI/CD stands for Continuous Integration/Continuous Deployment, a software development practice that enables developers to automate the building, testing, and deployment of code changes, ensuring that software is always in a releasable state.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "#",
                    key: "shaping",
                    id: "shaping",
                    title: "Shaping",
                    quadrant: "processpatterns",
                    description: "Shaping is the process of defining and refining the scope, goals, and requirements of a software project, ensuring that the project is well-defined, feasible, and aligned with the organization's strategic objectives.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "trial",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "#",
                    key: "terraform",
                    id: "terraform",
                    title: "Terraform",
                    quadrant: "languagesframeworks",
                    description: "Terraform is an open-source infrastructure as code software tool that enables developers to define and provision data center infrastructure using a declarative configuration language.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "hold",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "#",
                    key: "arm",
                    id: "arm",
                    title: "ARM",
                    quadrant: "languagesframeworks",
                    description: "ARM stands for Azure Resource Manager, a management service that enables you to deploy, manage, and monitor resources in your Azure environment.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "hold",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "#",
                    key: "bicep",
                    id: "bicep",
                    title: "Bicep",
                    quadrant: "languagesframeworks",
                    description: "Bicep is a Domain Specific Language (DSL) for deploying Azure resources declaratively.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "#",
                    key: "dotnet8",
                    id: "dotnet8",
                    title: ".NET 8",
                    quadrant: "languagesframeworks",
                    description: ".NET 8 is the latest version of the .NET framework, providing developers with a powerful and flexible platform for building a wide range of applications, from web and mobile apps to cloud services and APIs.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "#",
                    key: "csharp",
                    id: "csharp",
                    title: "C#",
                    quadrant: "languagesframeworks",
                    description: "C# is a modern, object-oriented programming language developed by Microsoft as part of the .NET platform. It is widely used for building a variety of applications, from web and mobile apps to cloud services and APIs.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "#",
                    key: "kotlin",
                    id: "kotlin",
                    title: "Kotlin",
                    quadrant: "languagesframeworks",
                    description: "Kotlin is a modern, expressive, and powerful programming language developed by JetBrains for the Java Virtual Machine (JVM) and Android. It is designed to be fully interoperable with Java, enabling developers to use both languages in the same project.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "assess",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "#",
                    key: "oauth2",
                    id: "oauth2",
                    title: "OAuth2",
                    quadrant: "languagesframeworks",
                    description: "OAuth2 is an open standard for access delegation, commonly used as a way for Internet users to grant websites or applications access to their information on other websites but without giving them the passwords.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "#",
                    key: "cloud-events",
                    id: "cloud-events",
                    title: "Cloud Events",
                    quadrant: "languagesframeworks",
                    description: "CloudEvents is a specification for describing event data in a common way, enabling interoperability between cloud services and event sources.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "#",
                    key: "grpc",
                    id: "grpc",
                    title: "gRPC",
                    quadrant: "languagesframeworks",
                    description: "gRPC is a high-performance, open-source, universal RPC framework that enables efficient communication between services in a variety of programming languages.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "assess",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "#",
                    key: "azure-cloud-adoption-framework",
                    id: "azure-cloud-adoption-framework",
                    title: "Cloud Adoption Framework",
                    quadrant: "languagesframeworks",
                    description: "Azure Cloud Adoption Framework is a collection of best practices, documentation, and tools that help organizations design and implement effective cloud adoption strategies, ensuring that they achieve their business goals and objectives.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "assess",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "#",
                    key: "async-api",
                    id: "async-api",
                    title: "AsyncAPI",
                    quadrant: "languagesframeworks",
                    description: "AsyncAPI is a specification for describing event-driven APIs in a machine-readable format, enabling the generation of documentation, code, and tests for event-driven systems.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "#",
                    key: "open-api",
                    id: "open-api",
                    title: "OpenAPI",
                    quadrant: "languagesframeworks",
                    description: "OpenAPI is a specification for building APIs that enables developers to design, build, and document RESTful web services, ensuring that APIs are consistent, reliable, and easy to use.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "use",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "#",
                    key: "azure-landing-zones",
                    id: "azure-landing-zones",
                    title: "Landing Zones",
                    quadrant: "languagesframeworks",
                    description: "Azure Landing Zones are a set of best practices, guidelines, and tools that help organizations design and implement a well-architected environment in Azure, ensuring that they achieve their business goals and objectives.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "assess",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                },
                {
                    url: "#",
                    key: "hybrid-cloud",
                    id: "hybrid-cloud",
                    title: "Hybrid Cloud",
                    quadrant: "platformstools",
                    description: "Hybrid Cloud is a computing environment that combines public cloud services and private cloud services, enabling organizations to leverage the benefits of both while maintaining control over sensitive data and applications.",
                    timeline: [
                        {
                            moved: 0,
                            ringId: "assess",
                            date: new Date("2024-05-20"),
                            description: ""
                        },
                    ]
                }
            ],
        };
    }
}