import {createApiRef, DiscoveryApi} from '@backstage/core-plugin-api';

export interface DeliveryIndicatorApi {
    getExternalData(customerName : string): Promise<ReliabilityData>;
    getFlowData(customerName : string): Promise<FlowData>;
}

export interface FlowData {
    periodStart: string;
    periodEnd: string;
    deploymentFrequency: number;
}

export interface SystemReliabilityData {
    systemName: string;
    mtbf: number;
}

export interface ReliabilityData {
    systems: SystemReliabilityData[];
    periodStart: string;
    periodEnd: string;
    mtbf: number;
}

export class DeliveryIndicatorPluginApiImpl implements DeliveryIndicatorApi {
    discoveryApi: DiscoveryApi

    constructor({discoveryApi}: {discoveryApi: DiscoveryApi}) {
        this.discoveryApi = discoveryApi;
    }

    async getExternalData(customerName : string): Promise<ReliabilityData> {
        
        const proxyUri = `${await this.discoveryApi.getBaseUrl('proxy')}/healthMetrics`;
        const response = await fetch(`${proxyUri}/reliability?customer=${customerName}`);
        //  'https://intropy-apim.azure-api.net/delivery-indicator);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    }

    async getFlowData(customerName : string): Promise<FlowData> {

        const proxyUri = `${await this.discoveryApi.getBaseUrl('proxy')}/deliveryIndicators`;
        const response = await fetch(`${proxyUri}/flow?customer=${customerName}`);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    }
}

export const deliveryIndicatorApiRef = createApiRef<DeliveryIndicatorApi>({
    id: 'plugin.deliveryindicator.api',
});
