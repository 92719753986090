import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classes.svg}
      viewBox="0 0 26 39"
      fill="none"
    >
      <path
        d="M3.57563 4.9661C3.26132 5.09468 2.92462 5.15959 2.58504 5.15707C1.13704 5.15707 3.32956e-05 4.04407 3.32956e-05 2.57907C-0.00169692 2.2394 0.0640225 1.90277 0.193393 1.5887C0.322763 1.27462 0.513216 0.989363 0.75368 0.749457C0.994144 0.509551 1.27984 0.319775 1.59421 0.191135C1.90858 0.0624946 2.24538 -0.00244893 2.58504 7.05966e-05C2.9247 -0.00244893 3.26148 0.0624946 3.57585 0.191135C3.89023 0.319775 4.17594 0.509551 4.4164 0.749457C4.65686 0.989363 4.8473 1.27462 4.97667 1.5887C5.10604 1.90277 5.17176 2.2394 5.17003 2.57907C5.17163 2.91866 5.10581 3.25518 4.97638 3.56914C4.84695 3.88309 4.65648 4.16824 4.41603 4.40804C4.17558 4.64784 3.88993 4.83752 3.57563 4.9661Z"
        fill="#B3F2E3"
      />
      <path
        d="M25.288 28.9161H21.058L21.059 28.9151V15.9001C21.059 13.3681 19.33 11.5801 16.867 11.5801C14.403 11.5801 12.637 13.3601 12.637 15.9001V28.2031H12.645V32.8301C12.645 36.2301 9.91603 38.9931 6.55803 38.9931C3.20003 38.9931 0.470035 36.2301 0.470035 32.8301V8.45007H4.70003V32.8301C4.69819 33.0745 4.74458 33.317 4.83655 33.5435C4.92852 33.77 5.06428 33.9761 5.23603 34.1501C5.40777 34.3241 5.61215 34.4625 5.83745 34.5574C6.06276 34.6523 6.30456 34.7018 6.54903 34.7031C6.79358 34.7019 7.03551 34.6525 7.26093 34.5577C7.48635 34.4628 7.69083 34.3245 7.86269 34.1505C8.03454 33.9765 8.17039 33.7703 8.26242 33.5437C8.35446 33.3171 8.40088 33.0746 8.39904 32.8301V15.8241C8.39904 11.0661 11.878 7.81207 16.82 7.81207C21.763 7.81207 25.288 11.1051 25.288 15.9861V28.9161Z"
        fill="#B3F2E3"
      />
      <path
        d="M20.588 34.7941C20.588 33.3291 21.725 32.2161 23.173 32.2161C23.5126 32.2136 23.8493 32.2785 24.1636 32.407C24.4779 32.5356 24.7636 32.7253 25.004 32.9651C25.2445 33.2049 25.4349 33.49 25.5644 33.804C25.6938 34.118 25.7596 34.4545 25.758 34.7941C25.758 36.2601 24.621 37.3731 23.173 37.3731C21.725 37.3731 20.588 36.2601 20.588 34.7941Z"
        fill="#B3F2E3"
      />
    </svg>
  );
};

export default LogoIcon;
