import {createApiFactory, createPlugin, createRoutableExtension, discoveryApiRef} from '@backstage/core-plugin-api';
import { deliveryIndicatorApiRef, DeliveryIndicatorPluginApiImpl } from './api';

import { rootRouteRef } from './routes';

export const metricsViewerPlugin = createPlugin({
    id: 'metrics-viewer',
    routes: {
        root: rootRouteRef,
    },
});

export const deliveryIndicatorPlugin = createPlugin({
    id: 'plugin.deliveryindicator.api',
    routes: {
        root: rootRouteRef,
    },
    // Configure a factory for myAwesomeApiRef
    apis: [
        createApiFactory({
            api: deliveryIndicatorApiRef,
            deps: { discoveryApi: discoveryApiRef },
            factory: ({ discoveryApi }) => new DeliveryIndicatorPluginApiImpl({ discoveryApi }),
        }),
    ],
});

export const EntityTeamMetricsCard = deliveryIndicatorPlugin.provide(
    createRoutableExtension({
        name: 'MetricsViewerPage',
        component: () =>
            import('./components/MetricComponent').then(m => m.TeamMetricComponent),
        mountPoint: rootRouteRef,
    }),
);

export const EntitySystemMetricsCard = deliveryIndicatorPlugin.provide(
    createRoutableExtension({
        name: 'MetricsSystemViewerPage',
        component: () =>
            import('./components/MetricComponent').then(m => m.SystemMetricComponent),
        mountPoint: rootRouteRef,
    }),
);

export const ContentMtbfMetrics= deliveryIndicatorPlugin.provide(
    createRoutableExtension({
        name: 'MetricsMtbfViewerPage',
        component: () =>
            import('./components/MetricComponent').then(m => m.MtbfMetricComponent),
        mountPoint: rootRouteRef,
    }),
);
